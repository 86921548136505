import {Route, Routes, Link} from "react-router-dom"

function App() {

function Home(){
  return(
    <>
 <h2>
 HOME
  </h2>  
    </>
  )
}


function Aboutus(){
  return(
    <>
    <h2>
    about us
    </h2>
   
    </>
  )
}

  return (
   <>
 <Link to={"/home"}  >home</ Link>
 <Link to={"/aboutus"}  >about us</ Link>


 <Routes>
   <Route path="/" element={<Home/>} />
   <Route path="/home"  element={<Home />}  />
   <Route path="/aboutus" element={<Aboutus/>} />
 </Routes>


   </>
  ); 
}

export default App;
